import { IStackItemStyles, ITheme } from '@fluentui/react'
import { format } from 'date-fns'

export const stackSubTitleStyles: Partial<IStackItemStyles> = {
  root: { padding: '10px 0px', gap: 32, backgroundColor: '#84744D' }
}
export const getComponentStyles = (): {
  evenWrappedStack: IStackItemStyles
  requestorStack: IStackItemStyles
} => ({
  evenWrappedStack: {
    root: {
      maxWidth: '100%',
      minWidth: '300px',
      flexBasis: 0,
      height: 'auto'
    }
  },
  requestorStack: {
    root: {
      maxWidth: '525px',
      minWidth: '300px',
      flexBasis: 0,
      height: 'auto'
    }
  }
})

export const getThemedClasses = (theme: ITheme) => {
  return {
    paletteOverflow: {
      overflow: 'auto'
    },
    listContainer: {
      minHeight: '400px'
    },
    listMenu: {
      backgroundColor: theme.semanticColors.bodyBackground,
      marginBottom: '1px',
      display: 'inline-block'
    }
  }
}
export const stackContainer: Partial<IStackItemStyles> = {
  root: { paddingLeft: '15px !important' }
}
export const onFormatDate = (date?: Date | undefined): string => {
  return date ? format(new Date(date), 'MM/dd/yyyy') : ''
}
export const b64toBlob = (b64Data: string, sliceSize = 512) => {
  const dataParts = b64Data.split(',')
  const contentType = dataParts?.[0]
    ?.replace('data:', '')
    ?.replace(';base64', '')
  const byteCharacters: string = atob(dataParts?.[1])
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}
