import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import {
  ICheckDepositApiReq,
  IGetAdvisorAccountsByClientIdApiRes,
  IGetCheckDepositByTransactionNoApiRes,
  IGetTransactionSeqNoApiRes,
  IInsertUpdateCheckDepositApiReq,
  IInsertUpdateCheckDepositApiRes
} from './types'

const fetchAdvisorAccountsByClientIdService = (
  clientId: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/GetAdvisorAccounts?clientId=${clientId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGetAdvisorAccountsByClientIdApiRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchCheckDepositByTransactionNoService = (
  options: IApiOptions,
  req?: ICheckDepositApiReq
) => {
  const url = `${options.apiRoot}/cdm/CheckDeposit/GetRequest`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IGetCheckDepositByTransactionNoApiRes>(url, req ?? {}, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const insertUpdateCheckDepositService = (
  req: IInsertUpdateCheckDepositApiReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/CheckDeposit/SetRequest`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IInsertUpdateCheckDepositApiRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const getTransactionSeqNoService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/CheckDeposit/GetTransactionSeqNo`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGetTransactionSeqNoApiRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export {
  fetchAdvisorAccountsByClientIdService,
  fetchCheckDepositByTransactionNoService,
  insertUpdateCheckDepositService,
  getTransactionSeqNoService
}
