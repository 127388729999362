import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../types'
import { AuditStatementReducer } from './store/reducer'
import { AuditStatementsagas } from './store/sagas'

const AuditStatementContainer = lazy(() => import('./AuditStatementContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/auditstatement/*',
    private: true,
    component: AuditStatementContainer
  },
  name: 'AuditStatement'
}

export default moduleDefinition

export const auditStatementModuleReducer = combineReducers({
  dasboard: AuditStatementReducer
})

export const auditStatamentModuleSagas = [...AuditStatementsagas]
