import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../types'
import { dashboardReducer } from './features/DashBoard/store/reducers'
import { dashboardSagas } from './features/DashBoard/store/sagas'
import { rcmcdReducer } from './store/reducers'
import { RCMCDsagas } from './store/sagas'

const CheckDepositContainer = lazy(() => import('./CheckDepositContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/advisor/*',
    private: true,
    component: CheckDepositContainer
  },
  name: 'RCMCheckDeposit'
}

export default moduleDefinition

export const advisorModuleReducer = combineReducers({
  rcmcd: rcmcdReducer,
  dashboard: dashboardReducer
})

export const advisorModuleSagas = [...dashboardSagas, ...RCMCDsagas]
