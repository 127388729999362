import { MessageBarType } from '@fluentui/react'
import { createReducer } from 'typesafe-actions'
import { IAuditStatementApiResp, IAuditStatementLogsRes } from '../api/types'
import {
  auditStatementActions,
  AuditStatementActionTypes,
  fetchAuditStatementClientsActions,
  fetchAuditStatementLogsActions,
  generateAuditStatementActions
} from './actions'
import { IIAuditStatementClientsApiRes } from './types'

export interface IAuditStatementState {
  fetchAuditStatementClientsState?: {
    inProgress: boolean
    errorCode?: string
    msg?: string
    messageType?: MessageBarType
    data?: IIAuditStatementClientsApiRes
  }
  generateAuditStatementState?: {
    inProgress: boolean
    errorCode?: string
    msg?: string
    messageType?: MessageBarType
  }
  auditStatementState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    data?: IAuditStatementApiResp
    msg?: string
  }
  fetchAuditStatementLogsState?: {
    inProgress: boolean
    errorCode?: string
    msg?: string
    messageType?: MessageBarType
    data?: IAuditStatementLogsRes
  }
}

const initialState: IAuditStatementState = {
  fetchAuditStatementClientsState: {
    inProgress: false,
    errorCode: undefined,
    msg: undefined,
    messageType: undefined,
    data: undefined
  }
}

export const AuditStatementReducer = createReducer<
  IAuditStatementState,
  AuditStatementActionTypes
>(initialState)
  .handleAction(fetchAuditStatementClientsActions.request, (state) => ({
    ...state,
    fetchAuditStatementClientsState: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchAuditStatementClientsActions.success, (state, action) => ({
    ...state,
    fetchAuditStatementClientsState: {
      inProgress: false,
      error: undefined,
      data: action.payload,
      messageType: MessageBarType.success
    }
  }))
  .handleAction(fetchAuditStatementClientsActions.failure, (state, action) => ({
    ...state,
    fetchAuditStatementClientsState: {
      inProgress: false,
      error: action.payload,
      data: undefined,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(fetchAuditStatementClientsActions.clear, (state) => ({
    ...state,
    fetchAuditStatementClientsState: undefined
  }))
  .handleAction(generateAuditStatementActions.request, (state) => ({
    ...state,
    generateAuditStatementState: {
      ...state.generateAuditStatementState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(generateAuditStatementActions.success, (state) => ({
    ...state,
    generateAuditStatementState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully Downloaded Audit Statement'
    }
  }))
  .handleAction(generateAuditStatementActions.failure, (state, action) => ({
    ...state,
    generateAuditStatementState: {
      ...state.generateAuditStatementState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(generateAuditStatementActions.clear, (state) => ({
    ...state,
    generateAuditStatementState: undefined
  }))

  .handleAction(auditStatementActions.request, (state) => ({
    ...state,
    generatePDFState: {
      ...state.auditStatementState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'Get Audit Statement '
    }
  }))
  .handleAction(auditStatementActions.success, (state, action) => ({
    ...state,
    auditStatementState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully fetched Audit Statement',
      data: action.payload
    }
  }))
  .handleAction(auditStatementActions.failure, (state, action) => ({
    ...state,
    auditStatementState: {
      ...state.auditStatementState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(fetchAuditStatementLogsActions.request, (state) => ({
    ...state,
    fetchAuditStatementLogsState: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchAuditStatementLogsActions.success, (state, action) => ({
    ...state,
    fetchAuditStatementLogsState: {
      inProgress: false,
      error: undefined,
      data: action.payload,
      messageType: MessageBarType.success
    }
  }))
  .handleAction(fetchAuditStatementLogsActions.failure, (state, action) => ({
    ...state,
    fetchAuditStatementLogsState: {
      inProgress: false,
      error: action.payload,
      data: undefined,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(fetchAuditStatementLogsActions.clear, (state) => ({
    ...state,
    fetchAuditStatementLogsState: undefined
  }))
