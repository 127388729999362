import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const HomeContainer: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/advisor', { replace: true })
    return
  }, [navigate])
  return <div />
}
