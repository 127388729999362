// import { b64toBlob } from 'modules/SPAAdvisor/store/utility'
import { b64toBlob } from 'modules/shared/utilities/utility'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { IEnvironmentApiConfiguration } from 'shared/services/environment/IEnvironmentConfiguration'
import {
  getRockefellerApiOptions,
  tryAcquireAccessToken
} from 'store/shared/sagas'
import { getRockefellerApiConfig } from 'store/system'
import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from 'typed-redux-saga'
import {
  deleteOcrFileService,
  fetchOcrUploadService,
  uploadOcrFileService,
  viewOcrFileService
} from '../api/ocrService'
import { DragDropFileStatus } from '../components/DragDrop'
import {
  fetchOcrFileActions,
  uploadOcrFileActions,
  uploadOcrActions,
  viewOcrFileActions,
  deleteOcrFileActions
} from './actions'

function* handleFetchOcrUpload(
  action: ReturnType<typeof fetchOcrFileActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchOcrUploadService(options, {
        ...action.payload
      })
    )
    if (response && response) {
      yield put(fetchOcrFileActions.success(response))
    } else {
      yield put(
        fetchOcrFileActions.failure(
          new Error('unable to fetch attachments list')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchOcrFileActions.failure(e))
  }
}

function* handleOcrUpload(
  action: ReturnType<typeof uploadOcrActions.uploadDoc>
) {
  const options = yield* call(getRockefellerApiOptions)
  const { onUploadSuccess, onUploadFail, onUploadProgress, fileDoc } =
    action.payload
  try {
    const data = yield* call(
      uploadOcrFileService,
      fileDoc,
      options,
      onUploadProgress
    )
    onUploadSuccess(fileDoc.docFile)

    if (fileDoc.docFile.fileStatus === DragDropFileStatus.SUCCESS) {
      yield put(uploadOcrFileActions.success(data))
    } else {
      yield put(
        uploadOcrFileActions.failure(
          new Error(
            'Unable to upload the file.Please try again after sometime.'
          )
        )
      )
    }
    yield put(fetchOcrFileActions.request({ attachmentType: 'ocr' }))
  } catch (e: any) {
    onUploadFail(fileDoc.docFile)
    yield put(uploadOcrFileActions.failure(e))
  }
}

function* handleViewOcrFile(
  action: ReturnType<typeof viewOcrFileActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      viewOcrFileService(
        action.payload.fileUrlPath,
        action.payload.downloadFileName,
        options
      )
    )

    yield put(viewOcrFileActions.success())
    // const pdfWindow = window.open('', 'statementWindow')
    // pdfWindow?.document.write(
    //   `<iframe width='100%' height='100%' src=
    //     ${encodeURI(data)}
    //     ></iframe>`
    // )
    const blob = b64toBlob(data)
    const fileURL = URL.createObjectURL(blob)
    window.open(fileURL, 'DocumentWindow')
  } catch (e: any) {
    yield put(viewOcrFileActions.failure(e))
  }
}

function* handleDeleteOcrFile(
  action: ReturnType<typeof deleteOcrFileActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)

  try {
    const data: string = yield* call(() =>
      deleteOcrFileService(
        action.payload.fileUrlPath,
        action.payload.attachmentType,
        options
      )
    )
    if (data) {
      yield put(deleteOcrFileActions.success())
    } else {
      yield put(
        deleteOcrFileActions.failure(new Error('Unable to delete attachment'))
      )
    }
  } catch (e: any) {
    yield put(
      deleteOcrFileActions.failure(
        new Error('Unable to delete attachment, please try after sometime.')
      )
    )
  }
}

export const OCRsagas = [
  () => takeEvery(uploadOcrActions.uploadDoc, handleOcrUpload),
  () => takeLatest(fetchOcrFileActions.request, handleFetchOcrUpload),
  () => takeLatest(viewOcrFileActions.request, handleViewOcrFile),
  () => takeLatest(deleteOcrFileActions.request, handleDeleteOcrFile)
]
