import { IAccount } from 'api/account.types'
import { ISearchParams, ISearchResult } from 'api/common.types'
import { OdataFilterOperatorEnum, OdataPropertyFilterGroup } from 'api/odata'
import { search } from 'api/search'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { IEnvironmentApiConfiguration } from 'shared/services/environment/IEnvironmentConfiguration'
import { getRockefellerApiOptions, tryAcquireAccessToken } from 'store/shared'
import { getRockefellerApiConfig } from 'store/system'
import { call, delay, put, select, takeLatest } from 'typed-redux-saga'
import {
  fetchAdvisorAccountsByClientIdService,
  fetchCheckDepositByTransactionNoService,
  getTransactionSeqNoService,
  insertUpdateCheckDepositService
} from '../api/rcmcdService'
import { IInsertUpdateCheckDepositApiReq } from '../api/types'
import {
  accountSearchActions,
  clientSearchActions,
  fetchAdvisorAccountsByClientIdActions,
  fetchCheckDepositServiceByTransactionNoActions,
  fetchTransactionNoAction,
  insertUpdateCheckDepositActions
} from './actions'

function* handleCheckDepositByTransactionNo(
  action: ReturnType<
    typeof fetchCheckDepositServiceByTransactionNoActions.request
  >
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchCheckDepositByTransactionNoService(options, action.payload)
    )

    if (response.success) {
      yield put(
        fetchCheckDepositServiceByTransactionNoActions.success(response)
      )
    } else {
      yield put(
        fetchCheckDepositServiceByTransactionNoActions.failure(
          new Error('Unable to get Check Deposit by transaction number')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchCheckDepositServiceByTransactionNoActions.failure(e))
  }
}

function* handleInsertUpdateCheckDeposit(
  action: ReturnType<typeof insertUpdateCheckDepositActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const payload: IInsertUpdateCheckDepositApiReq = action.payload

    const response = yield* call(() =>
      insertUpdateCheckDepositService(payload, options)
    )

    yield put(
      insertUpdateCheckDepositActions.success({
        ...response,
        msg: 'Successfully submitted the request'
      })
    )
  } catch (e: any) {
    yield put(
      insertUpdateCheckDepositActions.failure(
        new Error(e?.response?.data?.msg ?? e)
      )
    )
  }
}

function* handleAdvisorAccountsByClientId(
  action: ReturnType<typeof fetchAdvisorAccountsByClientIdActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchAdvisorAccountsByClientIdService(action.payload, options)
    )
    if (response.success) {
      yield put(fetchAdvisorAccountsByClientIdActions.success(response))
    } else {
      yield put(
        fetchAdvisorAccountsByClientIdActions.failure(
          new Error('Unable to get advisor account by client ID')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchAdvisorAccountsByClientIdActions.failure(e))
  }
}

function* handleAccountSearch(
  action: ReturnType<typeof accountSearchActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    const filter: OdataPropertyFilterGroup = {
      and: [
        {
          operator: OdataFilterOperatorEnum.eq,
          path: 'LegalEntityID',
          type: 'string',
          value: action.payload.LegalEntityID
        },
        {
          operator: OdataFilterOperatorEnum.eq,
          path: 'accountStatus',
          type: 'string',
          value: 'Open'
        }
      ]
    }
    const params: ISearchParams = {
      orderBy: [
        { dataPath: 'search.score()', direction: 'desc' },
        { dataPath: 'id', direction: 'desc' }
      ],
      filters: [filter]
    }

    const response: ISearchResult<IAccount> = yield call(
      search,
      'account' as const,
      {
        ...params,
        select: [
          'accountId',
          'ClientAdvisorID',
          'ClientAdvisor',
          'ClientAdvisorTeam',
          'LegalEntityName',
          'LegalEntityID',
          'CustodianName',
          'CustodianType',
          'CustodyAccount',
          'CustodianCode',
          'Accountsource'
        ]
      },
      options
    )
    if (response && response?.value) {
      yield put(accountSearchActions.success(response))
    } else {
      yield put(
        accountSearchActions.failure(new Error('unable to fetch account list'))
      )
    }
  } catch (e: any) {
    yield put(accountSearchActions.failure(e))
  }
}

function* handleClientSearch(
  action: ReturnType<typeof clientSearchActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      search(
        'client',
        {
          query: action.payload,
          fullQuery: true,
          searchFields: ['LegalEntityName', 'PartyUniqueId'],
          select: [
            'ClientAdvisorID',
            'ClientAdvisor',
            'ClientAdvisorTeam',
            'LegalEntityName',
            'LegalEntityID',
            'ClientKPI/AumTotal',
            'loginDetails',
            'Account',
            'srcClientNumber',
            'id',
            'PartyUniqueId',
            'qualifiedClient',
            'TrustCompany'
          ],
          count: true,
          top: 500
        },
        options
      )
    )
    if (response && response?.value) {
      yield put(clientSearchActions.success(response))
    } else {
      yield put(
        clientSearchActions.failure(new Error('unable to fetch client list'))
      )
    }
  } catch (e: any) {
    yield put(clientSearchActions.failure(e))
  }
}

function* handleTransactionSeqNo(
  action: ReturnType<typeof fetchTransactionNoAction.request>
) {
  try {
    console.log('action', action)
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() => getTransactionSeqNoService(options))
    if (response.success) {
      yield put(fetchTransactionNoAction.success(response.data[0]))
    } else {
      yield put(
        fetchTransactionNoAction.failure(
          new Error('Unable to generate Transaction Number')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchTransactionNoAction.failure(e))
  }
}

export const RCMCDsagas = [
  () =>
    takeLatest(
      fetchCheckDepositServiceByTransactionNoActions.request,
      handleCheckDepositByTransactionNo
    ),
  () =>
    takeLatest(
      insertUpdateCheckDepositActions.request,
      handleInsertUpdateCheckDeposit
    ),
  () =>
    takeLatest(
      fetchAdvisorAccountsByClientIdActions.request,
      handleAdvisorAccountsByClientId
    ),
  () => takeLatest(accountSearchActions.request, handleAccountSearch),
  () => takeLatest(clientSearchActions.request, handleClientSearch),
  () => takeLatest(fetchTransactionNoAction.request, handleTransactionSeqNo)
]
