import { combineReducers, Reducer } from 'redux'
import {
  auditStatamentModuleSagas,
  auditStatementModuleReducer
} from './AuditStatement'
import { ocrModuleReducer, ocrModuleSagas } from './OCR'
import { advisorModuleReducer, advisorModuleSagas } from './RCMCheckDeposit'

export const modulesSagas = [
  ...advisorModuleSagas,
  ...ocrModuleSagas,
  ...auditStatamentModuleSagas
]

interface IModulesState {
  RCMCD: ReturnType<typeof advisorModuleReducer>
  trust: any
  OCR: ReturnType<typeof ocrModuleReducer>
  AuditStatement: ReturnType<typeof auditStatementModuleReducer>
}

export type StaticModulesState = IModulesState

export const modulesReducer: Reducer<any> = combineReducers({
  RCMCD: advisorModuleReducer,
  OCR: ocrModuleReducer,
  AuditStatement: auditStatementModuleReducer
})
